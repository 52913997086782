import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Card, Container, Grid, Typography } from '@mui/material';

import PageNotFoundBg from 'assets/icons/page-not-found-bg';
import { themeColors } from 'theme/themeColors';

const ErrorPage = () => {
  const errorContent = useMemo(
    () => ({
      header: 'Something went wrong,',
      subTitle: ' can not find creative',
      title: 'Please be sure, that you have correct link',
      // description: ErrorDescription[type as keyof typeof apiErrors],
      // support: ErrorSupDescription[type as keyof typeof apiErrors],
    }),
    []
  );

  return (
    <Box
      sx={{ backgroundColor: themeColors.grey20 }}
      display="flex"
      flexDirection="column"
      minHeight="100vh"
    >
      <Container disableGutters>
        <Box pt={0} pb={3}>
          <Box mt={8} mb={4}>
            <Typography variant="h1" color="textPrimary" component="span">
              <FormattedMessage id={errorContent.header} />
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Proxima Nova Regular',
                color: themeColors.greyPrimary,
                ml: 0.5,
              }}
              variant="h1"
              component="span"
            >
              <FormattedMessage id={errorContent.subTitle} />
            </Typography>
          </Box>

          <Grid container>
            <Grid item xs={5}>
              <Card sx={{ p: 4, mb: 4, borderRadius: '3px' }}>
                <Typography variant="h3" sx={{ fontSize: '18px' }}>
                  <FormattedMessage id={errorContent.title} />
                </Typography>
                {/* <Typography sx={{ mt: 3 }}>
                  <FormattedMessage id={errorContent.description} />
                </Typography> */}

                {/* <Typography sx={{ mt: 3 }}>
                  <FormattedMessage id={errorContent.support} />
                </Typography> */}
              </Card>
            </Grid>

            <Grid
              item
              xs={7}
              sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
            >
              <PageNotFoundBg width={646} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export { ErrorPage };
