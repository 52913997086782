import { Box, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';

import { AppProvider } from 'components/app-provider';
import { QueryClientWrapper } from 'components/query-client-wrapper';
import { theme, themeColors } from 'theme';

import { AsyncBoundary } from './components/async-boundary/AsyncBoundary';
import { Routes } from './routes/Routes';

function App() {
  return (
    <AppProvider>
      <QueryClientWrapper>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <Box
                display="flex"
                sx={{ backgroundColor: themeColors.grey20 }}
                flexDirection="column"
                minHeight="100vh"
              >
                <AsyncBoundary>
                  <Routes />
                </AsyncBoundary>
              </Box>
            </CssBaseline>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientWrapper>
    </AppProvider>
  );
}

export default App;
