export enum AD_TYPE {
  SEAMLESS_IMAGE = 'SEAMLESS_IMAGE',
  SEAMLESS_IMAGE_L = 'SEAMLESS_IMAGE_L',
  SEAMLESS_IMAGE_XL = 'SEAMLESS_IMAGE_XL',
  LEADGEN_SEAMLESS_IMAGE = 'LEADGEN_SEAMLESS_IMAGE',
  LEADGEN_SEAMLESS_IMAGE_L = 'LEADGEN_SEAMLESS_IMAGE_L',
  LEADGEN_SEAMLESS_IMAGE_XL = 'LEADGEN_SEAMLESS_IMAGE_XL',
  SEAMLESS_IMAGE_MINI = 'SEAMLESS_IMAGE_MINI',
  SEAMLESS_VIDEO = 'SEAMLESS_VIDEO',
  SEAMLESS_VIDEO_L = 'SEAMLESS_VIDEO_L',
  SEAMLESS_VIDEO_XL = 'SEAMLESS_VIDEO_XL',
  LEADGEN_SEAMLESS_VIDEO = 'LEADGEN_SEAMLESS_VIDEO',
  LEADGEN_SEAMLESS_VIDEO_L = 'LEADGEN_SEAMLESS_VIDEO_L',
  LEADGEN_SEAMLESS_VIDEO_XL = 'LEADGEN_SEAMLESS_VIDEO_XL',
  SEAMLESS_CAROUSEL = 'SEAMLESS_CAROUSEL',
  NATIVE = 'NATIVE',
  ADOPTIMIZE_CLICKS = 'ADOPTIMIZE_CLICKS',
  DEALS = 'DEALS',
  LISTING_AD = 'LISTING_AD',
  SEAMLESS_IMAGE_CROSS_DEVICE = 'SEAMLESS_IMAGE_CROSS_DEVICE',
  BRANDED_CONTENT_VIDEO = 'BRANDED_CONTENT_VIDEO',
  TWEAKERS_ADVERTORIAL = 'TWEAKERS_ADVERTORIAL',
}

type ImageSettings = {
  offsetX: number;
  offsetY: number;
  scale: number;
  backgroundColor: string;
};

export type Icon = {
  fileUrl: string;
  imageSettings: ImageSettings;
};

export type Image = {
  fileUrl: string;
  imageSettings: ImageSettings;
};

export type Video = {
  vastTag: string;
};
