import { AppLayout } from 'components';
import React from 'react';
import { Routes as BaseRoutes, Outlet, Route, useLocation } from 'react-router-dom';

import { ErrorPage } from 'components/error-page';
import { OffsetContainer } from 'components/offset-container';

import { appRoutes } from './appRoutes';

const Creative = React.lazy(() => import('features/Creative'));

export const Routes = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const skipLayout = params.get('skipLayout');
  const systemName = params.get('systemName');

  const isAdManager = systemName === 'AD_MANAGER';
  const isSkipLayout = skipLayout === 'true';

  return (
    <BaseRoutes>
      <Route
        element={isSkipLayout ? <Outlet /> : <AppLayout isAdManager={isAdManager} />}
      >
        <Route
          path={appRoutes.preview}
          element={
            isSkipLayout ? (
              <Creative isSkipLayout={isSkipLayout} />
            ) : (
              <OffsetContainer>
                <Creative isSkipLayout={false} />
              </OffsetContainer>
            )
          }
        />
        <Route key={'*'} path="*" element={<ErrorPage />} />
      </Route>
    </BaseRoutes>
  );
};
