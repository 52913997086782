import React, { useState } from 'react';

import { AppContextType } from './types';

export const AppContext = React.createContext<AppContextType>({
  site: 'nunl',
  scale: 100,
});

interface Props {
  children: React.ReactNode;
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [site, setSite] = useState('nunl');
  const [scale, setScale] = useState(100);

  const context = {
    site,
    setSite,
    scale,
    setScale,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
