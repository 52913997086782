import React from 'react';

import { Grid } from '@mui/material';

import { styles } from 'components/footer/styles';

import { HelpLink } from './HelpLink';

const HelpLinks: React.FC = () => {
  return (
    <Grid
      item
      container
      justifyContent="flex-end"
      columnSpacing={10}
      sx={styles.helpLinks}
    >
      <Grid item>
        <HelpLink
          href="https://privacy.dpgmedia.nl/nl/document/terms-of-use"
          translationId="terms"
        />
      </Grid>
      <Grid item>
        <HelpLink href="https://www.dpgmediagroup.com/" translationId="dpg_media_group" />
      </Grid>
    </Grid>
  );
};

export { HelpLinks };
