import React from 'react';
import { convertSxToThemeType } from 'utils';

import { MenuItem, Select, SelectProps } from '@mui/material';

import { styles } from './styles';

type Props = SelectProps & {
  children?: React.ReactNode;
  options?: { value: number | string; label: string }[];
};

export const SelectMui: React.FC<Props> = (props) => {
  const clonedProps = {
    MenuProps: {
      PaperProps: {
        sx: styles.dropdown,
      },
    },
    sx: convertSxToThemeType([styles.select, styles.icon]),
    ...props,
  };

  return (
    <Select {...clonedProps}>
      {props.options &&
        props.options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      {props.children}
    </Select>
  );
};
