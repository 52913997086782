import { AD_TYPE } from 'api/types';

export const appRoutes = {
  home: '/',
  preview: '/preview',
};

export const getReadableTypeFromCreativeFormat = (creativeFormat: AD_TYPE) => {
  switch (creativeFormat) {
    case AD_TYPE.SEAMLESS_IMAGE:
    case AD_TYPE.SEAMLESS_IMAGE_L:
    case AD_TYPE.SEAMLESS_IMAGE_XL:
    case AD_TYPE.SEAMLESS_IMAGE_CROSS_DEVICE:
      return 'Seamless Image';

    case AD_TYPE.LEADGEN_SEAMLESS_IMAGE:
    case AD_TYPE.LEADGEN_SEAMLESS_IMAGE_L:
    case AD_TYPE.LEADGEN_SEAMLESS_IMAGE_XL:
      return 'Seamless Image + leadform';

    case AD_TYPE.SEAMLESS_VIDEO:
    case AD_TYPE.SEAMLESS_VIDEO_L:
    case AD_TYPE.SEAMLESS_VIDEO_XL:
      return 'Seamless Video';

    case AD_TYPE.LEADGEN_SEAMLESS_VIDEO:
    case AD_TYPE.LEADGEN_SEAMLESS_VIDEO_L:
    case AD_TYPE.LEADGEN_SEAMLESS_VIDEO_XL:
      return 'Seamless Video + leadform';
    case AD_TYPE.SEAMLESS_CAROUSEL:
      return 'Seamless Carousel';
    case AD_TYPE.NATIVE:
      return 'Native Image';
    case AD_TYPE.ADOPTIMIZE_CLICKS:
      return 'Adoptimize Clicks';

    default:
      return '';
  }
};
