import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import Ads from 'assets/icons/ads';
import { Icon } from 'components/icon';
import { OffsetContainer } from 'components/offset-container';
import { themeColors } from 'theme';

import { styles } from './styles';

const Header: React.FC<{ isAdManager: boolean }> = ({ isAdManager }) => {
  return (
    <OffsetContainer>
      <Box sx={styles.headerLayout}>
        <Box mr={8} display="flex" alignItems="center" height={107}>
          <Icon
            name={isAdManager ? 'dpg-ad-manager-logo' : 'dpg-header-logo'}
            width={isAdManager ? 257 : 151.5}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Ads />
          <Typography ml={1} color={themeColors.pink} variant="body1">
            <FormattedMessage id="breadcrumb" />
          </Typography>
        </Box>
      </Box>
    </OffsetContainer>
  );
};

export { Header };
