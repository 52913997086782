import React, { ErrorInfo, PropsWithChildren, ReactElement, Suspense } from 'react';

import { Loader } from 'components/loader';

interface AsyncBoundaryProps {
  errorFallback?: ReactElement | null;
  pendingFallback?: ReactElement;
}

export function AsyncBoundary({
  errorFallback,
  pendingFallback = <Loader width={150} />,
  children,
}: PropsWithChildren<AsyncBoundaryProps>) {
  return (
    <ErrorBoundary fallback={errorFallback}>
      <Suspense fallback={pendingFallback}>{children}</Suspense>
    </ErrorBoundary>
  );
}

interface ErrorBoundaryProps {
  fallback?: ReactElement | null;
}

interface ErrorBoundaryState {
  hasError: boolean;
  message: string;
  status?: number;
}

class ErrorBoundary extends React.Component<
  PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, message: '', status: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      message: error.message,
      status: (error as any).response.status,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { hasError, message, status } = this.state;
    const { children, fallback } = this.props;

    if (status === 500) {
      window.location.replace('/');
    }
    if (hasError) {
      return (
        fallback || (
          <>
            <div>Oh no! Something went wrong!</div>
            <div>{message}</div>
          </>
        )
      );
    }
    return children;
  }
}
