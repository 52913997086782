import React from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { ContentLayout } from 'components/content-layout';
import { Footer } from 'components/footer';
import { Header } from 'components/header';

import { styles } from './styles';

const AppLayout = ({ isAdManager }: { isAdManager: boolean }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      sx={styles.mainContainer}
    >
      <Container disableGutters maxWidth={false} sx={styles.header}>
        <Header isAdManager={isAdManager} />
      </Container>
      <Container disableGutters maxWidth={false} sx={styles.content}>
        <ContentLayout>
          <Outlet />
        </ContentLayout>
      </Container>
      <Footer />
    </Box>
  );
};

export { AppLayout };
